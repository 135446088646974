import { FC } from "react";
import { initializeIcons, Stack, Text } from "@fluentui/react";
import { FontSizes } from "@fluentui/react/lib/Styling";
import { colorPallete } from "../../../assets/color";
import "../FunctionalityButtonStack/index.css";
import DraftIcon from "../../../assets/Icons/svg/draftIcon";
import NegotiateIcon from "../../../assets/Icons/svg/negotiateIcon";
import SignIcon from "../../../assets/Icons/svg/signIcon";

interface FunctionalityButtonProps {
  buttonText: string;
  color: string;
  onClickFunction: () => void;
  // height: string;
  // width: string;
  // title:string;
  flex: string;
  icon: string;
}

const styles = {
  headerStyle: {
    fontSize: "1.25rem",
    color: colorPallete.white,
    marginLeft: "2vh",
    fontFamily: "Poppins",
  },
  iconClass: {
    fontSize: FontSizes.size42,
    color: colorPallete.white,
    margin: "3%",
  },
};
initializeIcons();
export const FunctionalityButton: FC<FunctionalityButtonProps> = (props) => {
  return (
    <Stack
      style={{
        backgroundColor: props.color,
        alignItems: "center",
        flex: props.flex,
        margin: "0% 0.8%",
        borderRadius: "0.4rem",
        cursor: "pointer",
        justifyContent: "flex-start",
        boxShadow:props.icon === "draft"? " 0px 4px 30px 0px rgba(0, 0, 0, 0.25)":" 0px 4px 20px 0px rgba(0, 0, 0, 0.26)",
        height: props.icon === "draft"? "106px":"100px",
        alignSelf:'center'
      }}
      className="Button"
      onClick={props.onClickFunction}
    >
      <Text style={styles.headerStyle}>{props.buttonText}</Text>
    </Stack>
  );
};
