export const ApiEndpoints={
    createProject :()=>'/projects',   //POST
    getAllProjects : ()=>'/projects/all',    //GET
    getFolderDetails:(id:number)=>`/projects/${id}`,    //GET
getDocumentsInFolder :()=>`/folders/files`,   //GET
getPdfsInFolder:(folderName:string)=>`/folders/${folderName}/pdfs`,    //GET
    uploadFile:()=>"/upload/file",   //POST
    uploadMultiFile:()=>'/upload/file',   //POST
    uploadNewVersion:()=>'/upload/file/version',  //POST
    createTemplate:()=>'/documents/copy',
    createTemplateVersion:()=>'/documents/copy/version',
    getPrecedentFiles : ()=>'/documents/precedent',   //GET
    getDocumentVersions:()=>'/documents/versions',    //GET
    createNewVersion:()=>"/documents/draft",  //GET
    search :()=>'/search/documents',    //GET
    searchFolders:()=>'/search/projects',//GET
    searchVersions:(rootDocumentId:number)=>`/search/document/${rootDocumentId}/versions`,
users :()=>'/users',
grantUserPermission: () => "/permissions/grant",   //GET
getVersionSharedAccess:()=>"/permissions/document",  //GET
docusign:()=>"/docusign/code",    //GET
createUser:()=>"/docusign/user",    //POST
    shareDocument:()=>"/share",    //POST
shareSentDocument:()=>"/file/share/grant",  //POST
    userDetails:()=>"/users/details",
    getDocumentLinks: ()=>"/documents/documentLinks",
    deleteDocument:(rootDocumentId:number)=>`/documents/root/${rootDocumentId}`,
    deleteVersion:(documentId:number)=>`/documents/version/${documentId}`,
    deleteProject:(projectId:number)=>`/projects/${projectId}`,
    grantPermissionFolder:()=>`/permissions/folder/files`,
    getWorkingGroup:(projectId:number)=>`/working-group/${projectId}`,
    UpdateUserDetails:()=>"/users/details"  ,  //PUT
    UpdateFirstTimeLogin:()=>"users/login/first", //PUT,
    changeStatusOfProject:()=>'/projects/status', //POST,
    getStatusesOfProject : ()=>'/projects/statuses' //GET
};
