import { FC, useState, FormEvent } from "react";
import { FormModal } from "../Modal";
import {
  DefaultButton,
  IButtonStyles,
  IStackStyles,
  IStackTokens,
  IStyleFunctionOrObject,
  ITextFieldStyleProps,
  ITextFieldStyles,
  Label,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";
import { HorizontalStack, VerticalStack } from "../../Stack";
import { colorPallete } from "../../../assets/color";
import { ProjectService } from "../../../services/ProjectService";
import Loader from "../../Loader";

const textFieldStyles: IStyleFunctionOrObject<
  ITextFieldStyleProps,
  ITextFieldStyles
> = {
  root: {
    // height: "44px",
    backgroundColor: "#F8F8F8",
    borderRadius: "8px",
    width: "100%",
    padding: "5px",
    border: "1px solid #E0E0E0",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Poppins",
  },
  field: {
    backgroundColor: "#F8F8F8",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    fontFamily: "Poppins",

    selectors: {
      "::placeholder": {
        color: "#717070",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        fontFamily: "Poppins",
      },
    },
  },
};
const styles = {
  label: {
    color: colorPallete.black1,
    fontSize: "14px",
    fontWeight: "700",
    fontFamily: "Poppins",
    lineHeight: "20px",
  },
};
const closeButtonStyles: IButtonStyles = {
  root: {
    height: "36px",
    backgroundColor: "rgb(24, 70, 143)",
    color: "rgb(255, 255, 255)",
    borderRadius: "6px",
    border: "1px solid rgb(24, 70, 143)",
    fontSize: "13px",
    lineHeight: "19.5px",
    alignItems: "center",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  rootHovered: {
    backgroundColor: "rgb(24, 70, 143)",
    color: "rgb(255, 255, 255)",
  },
};
const cancelbuttonStyles: IButtonStyles = {
  root: {
    height: "36px",
    backgroundColor: "rgb(228, 232, 236)",
    color: "rgb(50, 50, 50)",
    borderRadius: "6px",
    border: "1px solid rgb(228, 232, 236)",
    fontSize: "13px",
    lineHeight: "19.5px",
    alignItems: "center",
    fontWeight: 500,
    fontFamily: "Poppins",
  },
  rootHovered: {
    backgroundColor: "rgb(228, 232, 236)",
    color: "rgb(50, 50, 50)",
  },
};
const containerStackTokens: IStackTokens = { childrenGap: 5 };
const containerStyles: IStackStyles = {
  root: {
    // margin: "8px",
  },
};
const buttonGroupContainerStyles: IStackStyles = {
  root: {
    padding: "4px 16px",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "60px",
  },
};
const formItemStyles: IStackStyles = {
  root: {
    padding: "8px 16px",
  },
};
const buttonGroupStackTokens: IStackTokens = { childrenGap: "12px" };

type closeProjectModalProps = {
  type: string;
  projectId: number;
  showModal: () => void;
  hideModal: () => void;
  isModalOpen: boolean;
};
const LoadingScreen: FC = () => {
  return (
    <Stack
      styles={{
        root: {
          height: "100px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Loader />
    </Stack>
  );
};
const SuccessScreen: FC = () => {
  return (
    <Stack
      styles={{
        root: {
          height: "100px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Text style={{ color: colorPallete.btGreen, fontSize: "16px" }}>
        Status Changed Successfully.
      </Text>
    </Stack>
  );
};
const ErrorScreen: FC = () => {
  return (
    <Stack
      styles={{
        root: {
          height: "100px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Text style={{ color: colorPallete.btRed, fontSize: "16px" }}>
        Error Changing Status
      </Text>
    </Stack>
  );
};

export const CloseProjectModal: FC<closeProjectModalProps> = (
  props: closeProjectModalProps
) => {
  const valueFieldId = useId("valueField");
  const reasonFieldId = useId("reasonField");
  const [closeForm, setCloseForm] = useState({
    value: "",
    reason: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleChange = (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const { name } = event.target as HTMLInputElement;
    setCloseForm((prevData) => ({
      ...prevData,
      [name]: newValue || "",
    }));
  };
  const closeModal = () => {
    props.hideModal();
    setCloseForm({
      value: "",
      reason: "",
    });
  };
  const changeStatus = async () => {
    const payload = {
      ...closeForm,
      projectId: props.projectId,
      status:
        props.type === "Close"
          ? "Closed"
          : props.type === "Abandon"
          ? "Abandoned"
          : "Active",
    };
    setIsLoading(true);
    ProjectService.changeStatusOfProject(payload)
      .then((res) => {
        if (res.code === 200) setIsSuccess(true);
        else setIsError(true);
        setIsLoading(false);
        setCloseForm({
          value: "",
          reason: "",
        });
        setTimeout(() => {
          props.hideModal();
          setIsSuccess(false);
          setIsError(false);
        }, 2000);
      })
      .catch(() => {
        setIsError(true);
        setTimeout(() => {
          setCloseForm({
            value: "",
            reason: "",
          });
          props.hideModal();
          setIsSuccess(false);
          setIsError(false);
        }, 2000);
      });
  };
  return (
    <FormModal
      title={"Close Project"}
      // TitleBackgroundColor={"#18468F"}
      isModalOpen={props.isModalOpen}
      showModal={props.showModal}
      hideModal={props.hideModal}
      // fileName={props.fileName}
      width="610px"
      cancelButton
      // customCancel={disable}
    >
      {isLoading ? (
        <LoadingScreen />
      ) : isSuccess ? (
        <SuccessScreen />
      ) : isError ? (
        <ErrorScreen />
      ) : (
        <Stack tokens={containerStackTokens} styles={containerStyles}>
          <Stack styles={formItemStyles}>
            <Label required htmlFor={valueFieldId} style={styles.label}>
              Value
            </Label>
            <TextField
              id={valueFieldId}
              styles={textFieldStyles}
              borderless
              name="value"
              value={closeForm.value}
              onChange={handleChange}
              type="number"
              placeholder="Value of Project"
            />
          </Stack>
          <Stack styles={formItemStyles}>
            <Label required htmlFor={reasonFieldId} style={styles.label}>
              Reason
            </Label>
            <TextField
              id={reasonFieldId}
              multiline
              resizable={false}
              styles={textFieldStyles}
              borderless
              name="reason"
              value={closeForm.reason}
              onChange={handleChange}
              placeholder="Reason For Changing Status"
            />
          </Stack>
          <Stack className="horizontalLine"></Stack>
          <HorizontalStack
            styles={buttonGroupContainerStyles}
            tokens={buttonGroupStackTokens}
          >
            <DefaultButton
              text={`${props.type} Project`}
              styles={closeButtonStyles}
              onClick={changeStatus}
            />
            <DefaultButton
              text="Cancel"
              styles={cancelbuttonStyles}
              onClick={closeModal}
            />
          </HorizontalStack>
        </Stack>
      )}
    </FormModal>
  );
};
