import {
  FontSizes,
  IButtonStyles,
  IStackStyles,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import { FC, useEffect, useState } from "react";
import { DocumentInCard } from "../../components/Cards/DocumentInCard";
import { Document } from "../../models";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { getPrecedentDocuments } from "../redux/Documents/document";
import { motion } from "framer-motion";

const stackStyles: IStackStyles = {
  root: {
    width: "100%",
    // height: "14rem",
    // minHeight:"14rem",
    alignItems: "center",
    flexDirection: "row",
    backgroundColor: "",
    overflowX: "hidden",
    gap: "12px",
  },
};

const retryButtonStyles: IButtonStyles = {
  root: {
    width: "20px",
    margin: "1%",
    alignItems: "center",
  },
};

const styles = {
  textDivStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "",
    minHeight: "11rem",
  },
  loaderStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "11rem",
  },
  errorStyles: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    alignItems: "center",
    minHeight: "11rem",
  },
  textStyles: {
    fontSize: FontSizes.size18,
    fontFamily:"Poppins"
  },
};

export const PrecedentDocuments: FC = () => {
  const { isLoading, data, error } = useAppSelector(
    (state) => state.fetchPrecedentDocument
  );
  const [isError, setIsError] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [docData, setDocData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [itemsToShow, setItemToShow] = useState(0);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    if (screenWidth < 550) {
      setItemToShow(3);
    } else if (screenWidth < 650 && screenWidth > 550) {
      setItemToShow(4);
    } else if (screenWidth < 784 && screenWidth > 650) {
      setItemToShow(3);
    } else if (screenWidth < 1060 && screenWidth > 784) {
      setItemToShow(4);
    } else if (screenWidth > 1060 && screenWidth < 1460) {
      setItemToShow(6);
    } else {
      setItemToShow(6);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  useEffect(() => {
    data && setDocData(data);
  }, [data]);


  useEffect(() => {
    if (data.length === 0 || isError)
      dispatch(
        getPrecedentDocuments({
          limit: 12,
          offset: 0,
        })
      );
  }, [isError]);

  const loaderJSX = isLoading && (
    <Stack className={mergeStyles(styles.loaderStyles)}>
      <Loader />
    </Stack>
  );

  const errorJSX = !isLoading && error !== "" && (
    <Stack className={mergeStyles(styles.errorStyles)}>
      <Text className={mergeStyles(styles.textStyles)}>{t("loader")}</Text>
      <PrimaryButton
        styles={retryButtonStyles}
        onClick={() => setIsError(!isError)}
      >
        {t("retry")}
      </PrimaryButton>
    </Stack>
  );

  return (
    <Stack styles={stackStyles}>
      {loaderJSX}
      {errorJSX}
      {!isLoading &&
        !errorJSX &&
        docData.slice(0, itemsToShow).map((document: Document, i) => {
          return (
            document.documentVersion && (
              <motion.div
                key={document.documentId}
                animate={{
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    duration: 0.4,
                    delay: i * 0.3,
                  },
                }}
                initial={{
                  opacity: 0,
                  translateX: 50,
                }}
                style={{
                  borderRadius: "0.4rem",
                  backgroundColor: "",
                  alignItems: "center",
                  display: "flex",
                  height: "100%",
                }}
              >
                <DocumentInCard document={document} />
              </motion.div>
            )
          );
        })}
      {!isLoading && !errorJSX && data?.length === 0 && (
        <div style={styles.textDivStyle}>
          <Text className={mergeStyles(styles.textStyles)}>
            {t("no_prededent_files_found")}
          </Text>
        </div>
      )}
    </Stack>
  );
};
